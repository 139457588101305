$harmoniaSans: "Harmonia Sans Pro Cyr"
$karla: "Karla"

$bodyFont: $karla, sans-serif
$headingFont: $harmoniaSans, $bodyFont

/* Breakpoints */
$break480: 480px
$break600: 600px
$break768: 768px
$break992: 992px
$break1200: 1200px

/* Colors */

// general
$text100: #EFF7F8
$background100: #FFFFFF

// grey
$groundBlack: #1D1D1B
$grey600: #5A5A5A
$grey400: #9B9B9B
$grey200: #C8C8C8
$grey100: #EBEBEB
$grey50: #F3F3F3

// green
$greenButton: #107857
$green600: #1AA97C
$green400: #50C19D
$green200: #97D2BF
$green100: #EAF8F3

// red
$red800: #B13B3C
$alertRed: #FF5758
$red600: #F47C7D
$red200: #FFCACB
$red100: #FFEAEA

// greenish
$greenish800: #518B44
$leafGreen600: #63ED45
$greenish400: #97F184
$greenish200: #B6F0A9
$greenish100: #E2FEDC

// yellow
$yellow800: #C58E06
$yellowAlert600: #FFB600
$yellow400: #FBD26D
$yellow200: #FFEBBA
$yellow100: #F7F0E0

// blue
$blue800: #29AC98
$waterBlue600: #15EFCE
$blue400: #7CF0DF
$blue200: #A7F8EB
$blue100: #E6FFFB
