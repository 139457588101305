@use 'common'

@font-face
	font-family: common.$harmoniaSans
	src: url('/fonts/HarmoniaSansProCyr-Regular.woff2')
	font-style: normal
	font-weight: 400
	font-display: swap

@font-face
	font-family: common.$harmoniaSans
	src: url('/fonts/HarmoniaSansProCyr-Bold.woff2')
	font-style: normal
	font-weight: 700
	font-display: swap

html,
body
	color: #000000
	background-color: #E5E5E5
	padding: 0
	margin: 0
	font-family: common.$bodyFont

h1, h2, h3, h4, h5
	font-family: common.$headingFont

h1
	font-size: 2.625em
	line-height: 1.2
	@media (max-width: 768px)
		font-size: 2em

h2
	font-size: 1.5625em
	line-height: 1.05
	@media (max-width: 768px)
		font-size: 1.3em

a
	color: inherit
	text-decoration: none

button
	cursor: pointer

*
	box-sizing: border-box

fieldset
	border: 0
	padding: 0
	margin: 0
	display: block
